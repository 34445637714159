<template>
  <div>
    <admin-title title="Company Profiles">
      <template #description>
        Create company profiles to provide information about your business and store your logos, colors and other brand assets all in one place. This will help you save time when submitting new requests. You can also share your company profile with other members of your organization so they can collaborate on your requests and orders.
      </template>

      <template #actions>
        <v-btn v-if="user && user.role == 'client'" @click="showCreateDialog()" color="white primary--text" small>
          Create Company Profile
        </v-btn>

        <template v-if="user && (user.role == 'admin' || user.organization)">
          <v-card v-if="selected" outlined>
            <v-list-item @click="$refs.userDialog.showDialog()" class="px-2" dense>
              <v-list-item-avatar rounded>
                <user-photo
                  photoSize="thumb"
                  :id="selected"
                  :size="40"
                  rounded
                />
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class="heading-font">
                  {{ $store.getters['users/user'](selected).fullName }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ $store.getters['users/user'](selected).email }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-card>

          <v-btn 
            v-else
            @click="$refs.userDialog.showDialog()"
            color="white primary--text" 
            class="custom-shadow" 
            small
          >
            <v-icon left>mdi-account-outline</v-icon>
            Search User
          </v-btn>
        </template>
      </template>
    </admin-title>

    <!-- COMPANY PROFILES -->
    <div>
      <preloader v-if="status.getting" message="Please wait..." />

      <v-alert :value="!status.getting && !confProfiles.length" type="info" border="left" text>
        No company profile found.
      </v-alert>

      <v-sheet v-if="!status.getting && confProfiles.length" width="100%" class="gap-10 flex-wrap d-flex">
        <profile-card
          v-for="profile in confProfiles"
          :key="profile.id"
          :profile="profile"
          @rename="renameProfile"
          @delete="confirmDelete"
        />
      </v-sheet>
    </div>

    <!-- CREATE DIALOG -->
    <create-dialog ref="CreateDialog" />

    <!-- CONFIRM DELETE -->
    <confirm-delete
      :show="showDeleteDialog"
      :message="deleteMessage"
      :deleting="status.deleting"
      @cancel="closeDeleteDialog()"
      @confirmed="deleteConfirmed()"
      textConfirmation="Profile"
      captcha
    />

    <!-- FIND USER DIALOG -->
    <find-user-dialog ref="userDialog" @selected="userSelected" />
  </div>
</template>

<script>
import { orderBy, uniqBy } from 'lodash'
import { mapState, mapActions } from 'vuex'

import ProfileCard from './components/ProfileCard'
import CreateDialog from './components/CreateDialog'
import FindUserDialog from '../users/components/FindUserDialog.vue'

export default {
  metaInfo: {
    title: 'Company Profiles'
  },

  /*------------------------------------------------------------------------------
   * COMPONENTS
   *----------------------------------------------------------------------------*/
  components: {
    ProfileCard,
    CreateDialog,
    FindUserDialog,
  },

  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      toDelete: null,
      selected: null,
      deleteMessage: null,
      showDeleteDialog: false,
    }
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      user: state => state.user.user,
      status: state => state.brand_profile.status,
      profiles: state => state.brand_profile.profiles,
      companies: state => state.brand_profile.companies,
    }),

    confProfiles: function () {
      var profiles = this.profiles
      profiles = profiles.concat(this.companies)
      profiles = uniqBy(profiles, 'id')
      return orderBy(profiles, 'name', 'asc')
    }
  },

  /*------------------------------------------------------------------------------
   * WATCH
   *----------------------------------------------------------------------------*/
  watch: {
    'user': {
      handler(user) {
        if (user) {
          if (user.role === 'client') {
            if (!this.status.firstLoad) {
              this.getBrandProfiles()
            }
            if (!this.status.companiesFirstLoad) {
              this.getMyCompanies()
            }
          }
          else {
            this.$store.commit('brand_profile/setProfiles', {})
          }
        }
      },
      immediate: true
    }
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    ...mapActions('brand_profile', [
      'deleteBrandProfile',
      'getBrandProfiles',
      'getMyCompanies',
    ]),

    showCreateDialog() {
      this.$refs.CreateDialog.dialog = true
    },

    renameProfile(profile) {
      this.$store.commit('brand_profile/setData', profile)
      this.showCreateDialog()
    },

    confirmDelete(profile) {
      this.toDelete = profile
      this.deleteMessage = `Delete brand profile ${profile.name}?`
      this.showDeleteDialog = true
    },

    closeDeleteDialog() {
      this.toDelete = null
      this.deleteMessage = null
      this.showDeleteDialog = false
    },

    deleteConfirmed() {
      Promise.resolve(this.deleteBrandProfile(this.toDelete))
      .then(() => {
        this.closeDeleteDialog()
      })
    },

    userSelected(userid) {
      this.$store.commit('brand_profile/clearProfiles')
      this.getBrandProfiles(userid)
      this.getMyCompanies(userid)

      this.selected = userid
    }
  },
}
</script>