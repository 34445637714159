<template>
  <v-hover v-slot="{ hover }">
    <v-card 
      v-if="profile" 
      :to="{ name: $store.getters['brand_profile/isOwner'](profile.user) ? 'EditBrandProfile' : 'BrandProfile', params: { id: profile.id } }"
      :class="{ 'box-shadow' : hover }"
      class="transition"
      max-width="350" 
      width="100%"
      outlined
    > 
      <div class="d-flex align-center full-width">
        <div class="pa-5 flex-grow-1">
          <v-avatar color="#f4fbfe" class="mr-3 pa-4" rounded>
            <span class="font-weight-bold title primary--text">
              {{ getAbr(profile.name) }}
            </span>
          </v-avatar>

          <h5 class="d-inline">{{ profile.name }}</h5>
        </div>

        <template v-if="$store.getters['brand_profile/isOwner'](profile.user)">
          <v-divider vertical></v-divider>

          <div class="pa-2">
            <v-menu offset-y left>
              <template v-slot:activator="{ on }">
                <v-btn @click.prevent color="primary" v-on="on" dark icon>
                  <v-hover v-slot="{ hover }">
                    <v-icon :style="{ opacity: hover ? '1' : '0.3' }">mdi-dots-horizontal</v-icon>
                  </v-hover>
                </v-btn>
              </template>
              <v-list dense>
                <v-list-item @click="$emit('rename', profile)">
                  <v-list-item-title class="primary--text">Rename</v-list-item-title>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item @click="$emit('delete', profile)">
                  <v-list-item-title class="error--text">Delete Profile</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </template>
      </div>
    </v-card>
  </v-hover>
</template>

<script>
export default {
  /*------------------------------------------------------------------------------
   * PROPS
   *----------------------------------------------------------------------------*/
  props: {
    profile: Object
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    getAbr(name) {
      let names = name.split(" ")
      return `${ names[0].substring(0, 1) }${ names[1] ? names[1].substring(0, 1) : '' }`
    }
  }
}
</script>