<template>
  <v-dialog 
    v-model="dialog"
    :max-width="$dialog.content"
  >
    <v-card>
      <close-btn @click="closeDialog()" class="mt-3" overflow />

      <v-card-title class="font-weight-bold">
        <h4>{{ data.id ? 'Rename' : 'Create' }} Company Profile</h4>
      </v-card-title>
      <v-card-text>
        <v-form @submit.prevent="validateForm()" ref="form">
          <v-text-field
            v-model="data.name"
            :rules="[rules.required]"
            label="Brand Name"
            outlined
          ></v-text-field>

          <v-btn
            :loading="status.creating"
            @click="validateForm()"
            color="primary" 
            class="mr-1"
            small
          >
            {{ data.id ? 'Update' : 'Create' }}
          </v-btn>

          <v-btn @click="closeDialog()" text small>Cancel</v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import rules from '@/rules'
import { mapState, mapActions } from 'vuex'

export default {
  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      rules,
      dialog: false,
    }
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      data: state => state.brand_profile.data,
      status: state => state.brand_profile.status,
    })
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    ...mapActions('brand_profile', [
      'createProfile'
    ]),

    closeDialog() {
      this.dialog = false
      this.$store.commit('brand_profile/resetData')
      this.$refs.form.resetValidation()
    },

    validateForm() {
      if (this.$refs.form.validate()) {
        Promise.resolve(this.createProfile())
        .then(() => {
          this.closeDialog()
        })
      }
    }
  }
}
</script>